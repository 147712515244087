<template>
  <div class='loginPage' @click="visible = false">
    <div class="loginCont">
      <div class="title">{{appInfo.appShowName}}</div>
      <el-form ref="loginFormRef" :model="loginform" :rules="rules" label-width="" @submit.native.prevent>
        <el-form-item label="" prop="account">
          <el-input v-model="loginform.account" type="text" placeholder="请输入账号">
            <i slot="prepend" class="el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="pwd">
          <el-input v-model="loginform.pwd" type="password" show-password placeholder="请输入密码" @keyup.enter.native="submitform('loginFormRef')">
            <i slot="prepend" class="el-icon-unlock"></i>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="" prop="isPassing">
          <slide-verify :l="42" :r="10" :w="400" :h="100" ref="slideblock"
            @again="onAgain"
            @fulfilled="onFulfilled"
            @success="onSuccess"
            @fail="onFail"
            @refresh="onRefresh"
            :slider-text="text"
            :accuracy="accuracy"
            :imgs="imgs"
          ></slide-verify>
        </el-form-item> -->
        <el-form-item label="" prop="isRemember">
          <el-checkbox v-model="loginform.isRemember">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item label="">
          <!-- <el-button type="primary" class="loginBtn" @click="submitform('loginFormRef')">登录</el-button> -->
          <el-popover placement="top-end" width="400" trigger="manual" v-model="visible">
            <el-button slot="reference" type="primary" class="loginBtn" @click.stop="submitform('loginFormRef')">登录</el-button>
            <slide-verify :l="42" :r="10" :w="400" :h="100" ref="slideblock"
              @again="onAgain"
              @fulfilled="onFulfilled"
              @success="onSuccess"
              @fail="onFail"
              @refresh="onRefresh"
              :slider-text="text"
              :accuracy="accuracy"
              :imgs="imgs"
            ></slide-verify>
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
// import dragVerifyImgChip from 'vue-drag-verify-img-chip'
const vers1Img = require('@/assets/images/vers1.jpg')
const vers2Img = require('@/assets/images/vers2.jpg')
const vers3Img = require('@/assets/images/vers3.jpg')
const vers4Img = require('@/assets/images/vers4.jpg')
const vers5Img = require('@/assets/images/vers5.jpg')
const vers6Img = require('@/assets/images/vers6.jpg')
const vers7Img = require('@/assets/images/vers7.jpg')
const vers8Img = require('@/assets/images/vers8.jpg')
const vers9Img = require('@/assets/images/vers9.jpg')
const vers10Img = require('@/assets/images/vers10.jpg')
var vm;
export default {
  name:'login',
  inject: ['getAreaList'],//app.vue注入的依赖函数 
  data() {
    var validateIsPassing = (rule, value, callback)=>{
      if (value !== true) {  
        callback(new Error('请完成图片验证'));  
      } else {  
        callback();  
      }  
    };
    return {
      appInfo: {
        logoPicImg:'',//平台logo
        appShowName:'',//平台名称
      },
      //登录信息
      loginform: {
        account:'',
        pwd:'',
        isPassing: false,
        isRemember:false,//记住密码
      },
      // imgsrc: '',
      imgsrc: '',
      text: "按住滑块向右滑动->", // 设置滑块文字
      accuracy: 5,// 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      imgs:[vers1Img,vers2Img,vers3Img,vers4Img,vers5Img,vers6Img,vers7Img,vers8Img,vers9Img,vers10Img],

      //验证form格式
      rules :{
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min:4, message:'长度不能低于4位', trigger:'blur'},
          { pattern : /^[A-Za-z0-9]+$/g, message:'请输入字母、数字格式的字符'}
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { min:4, message:'长度不能低于4位', trigger:'blur'},
          // { pattern : /^[A-Za-z0-9]+$/g, message:'请输入字母、数字格式的字符'}
        ],
        isPassing: [
          { required: true, message: '请完成图片验证', trigger: 'change' },
          { validator: validateIsPassing, trigger: 'change' },
        ],
      },

      visible:false,
      
    }
  },
  components:{
    // dragVerifyImgChip,
  },
  created(){
    vm = this
    // this.reimg()
    // this.getAppVo()
    let rememberInfo = vm.$cookie.get('rememberInfo')
    if(rememberInfo){
      this.loginform = JSON.parse(rememberInfo)
    }
    this.loginform.isPassing = false
  },
  mounted(){
    // let token = vm.$cookie.get('token')
    // if(token){
    //   router.push({
    //     path:'/index'
    //   })
    // }
    let platInfo = this.vtp.get('platInfo')
    if(platInfo){
      this.appInfo = {
        logoPicImg:'',
        appShowName:platInfo.appShowName,
      }
    }
  },
  //方法
  methods:{
    // 验证通过
    onSuccess(times) {
      console.log("验证通过，耗时 " + times + "毫秒");
      this.loginform.isPassing = true
      this.loginFun()
    },
    // 验证失败
    onFail() {
      console.log("验证不通过");
    },
    // 滑块上的刷新
    onRefresh() {
      console.log("点击了刷新小图标");
      this.loginform.isPassing = false
    },
    // 刷新后执行的回调函数
    onFulfilled() {
      console.log("刷新成功啦！");
    },
    // 检测是否人为操作
    onAgain() {
      // 刷新
      this.handleClick()
    },
    // 重置刷新
    handleClick() {
      this.$refs.slideblock.reset();
    },
    // reimg() {
    //   let versArr = [vers1Img,vers2Img,vers3Img,vers4Img,vers5Img,vers6Img,vers7Img,vers8Img,vers9Img,vers10Img]
    //   const randomIndex = Math.floor(Math.random() * versArr.length); // 随机选择一个索引
    //   if(this.imgsrc == versArr[randomIndex]){
    //     this.reimg()
    //   }else{
    //     this.imgsrc = versArr[randomIndex]
    //   }
    // },
    // //校验通过
    // passcallback() {
    //   console.log('pass');
    // },
    //验证form
    submitform(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          vm.visible = true
          // vm.loginFun()
        } else {
          // console.log('error submit!')
          return false
        }
      })
    },
    //登录
    async loginFun(){
      let dataObj = {
        'account': vm.loginform.account,
        'pwd':vm.$md5(vm.loginform.pwd),
        'appCode':'APP_SECURITY_PC',
      }
      const res = await apiUrl.login(qs.stringify(dataObj))
      if(res?.code==200){
        vm.$message.success('登录成功')
        vm.$store.state.token = res.data?.token
        vm.$cookie.set('token',res.data?.token)
        vm.vtp.set('userInfo',res.data.user)
        if(vm.loginform.isRemember){
          vm.$cookie.set('rememberInfo',JSON.stringify(vm.loginform))
        }else{
          vm.$cookie.delete('rememberInfo')
        }
        await this.getAreaList()
        vm.$router.push({
          path:'/index'
        })
      }else{
        vm.$message.error(res.message)
        vm.handleClick()
        vm.visible = false
      }
    },
  },

}
</script>
<style lang='scss'>
.loginPage{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url('../assets/images/bg.jpg') center center no-repeat;
  .loginCont{
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 400px;
    padding: 10px 20px;
    border-radius: 11px;
    box-shadow:0px 0px 10px 10px rgba(0,0,0,.6);
    .title{
      font-size: 30px;
      text-align: center;
      margin-bottom: 40px;
      // background-image: linear-gradient(to bottom, #fff, #1783c8);
      // color: transparent;
      // -webkit-background-clip: text;
      color: #fff;
      letter-spacing:2px;
    }
    .el-checkbox{
      color: #fff;
    }
    .loginBtn{
      margin: 0 auto;
      width: 100%;
    }
  }
  .slide-verify-slider{
    margin-top:0 !important;
  }
}
</style>
